import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Layout from "../components/Layout";
const styles = require("../styles/richText.module.scss");

const Page = ({ data }) => {
  const items = Object.entries(data);

  return (
    <Layout
      seo={{
        title: items[1].metaData?.pageTitle,
        description: items[1].metaData?.pageTitle,
      }}
    >
      <div className={styles.copyWrap}>
        {items.map((item) => (
          <>
            <h1 className={`${styles.header} h2 `}>{item[1].heading}</h1>
            <div className={styles.content}>
              {item[1].content.map((component) => {
                const { strapi_component, id } = component;
                switch (strapi_component) {
                  case "simple.coloured-copy-block":
                    const { copy, backgroundColor } = component;

                    return (
                      <div
                        key={id}
                        className={`${styles.simpleColouredCopyBlock} bkg-${backgroundColor}`}
                      >
                        {copy ? parse(copy) : null}
                      </div>
                    );

                  case "simple.rich-text-area":
                    const { text } = component;
                    return (
                      <div className={styles.textBlock} key={id}>
                        {text ? parse(text) : null}
                      </div>
                    );

                  default:
                    return null;
                }
              })}
            </div>
          </>
        ))}
        <h1 className={styles.header}>Cookie Notice</h1>
        <div className={styles.content}>
          <div className={styles.textBlock}>
            {/* < OneTrust Cookies Settings button start > */}
            <button
              id="ot-sdk-btn"
              className={`ot-sdk-show-settings ui ${styles.one_trust_overrides} button`}
            >
              Cookie Settings
            </button>
            {/* </ OneTrust Cookies Settings button end > */}
            {/* < OneTrust Cookies List start > */}
            <div id="ot-sdk-cookie-policy"></div>
            {/* </ OneTrust Cookies List end > */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    strapiInvestorPrivacyNotice {
      content
      heading

      metaData {
        pageTitle
        metadescription
      }
    }
    strapiManagerPrivacyNotice {
      content
      heading

      metaData {
        pageTitle
        metadescription
      }
    }
    strapiPrivacyAndCookies {
      content
      heading

      metaData {
        pageTitle
        metadescription
      }
    }
  }
`;

export default Page;
